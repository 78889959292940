<template>
    <v-container fluid>
        <v-row dense>

            <template v-if="loading">
                <v-col cols="12" class="text-center" mt-4>
                    <v-progress-circular
                        :width="3"
                        color="blue"
                        indeterminate
                    ></v-progress-circular>
                </v-col>
            </template>

            <template v-else>
                <v-col cols="12">
                <v-card outlined>
                <v-card-title>
                Lookup Orders By Member
                </v-card-title>
                <v-card-text>
                <v-autocomplete
                    v-model="model"
                    :items="searchMembers"
                    :loading="loading"
                    background-color="gray"
                    outlined
                    hide-no-data
                    hide-selected
                    item-text="search"
                    item-value="uid"
                    placeholder="Search for member name or #"
                    autofocus
                    return-object
                ></v-autocomplete>
                <v-card-actions class="mt-n4">
                <v-btn
                    :disabled="!model"
                    @click="model = null" 
                >
                    Clear
                    <v-icon right>mdi-close-circle</v-icon>
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                    :disabled="!model"
                    @click="loadSelectedMemberOrders" 
                >
                    Refresh
                    <v-icon right>mdi-reload</v-icon>
                </v-btn>
                </v-card-actions>
                </v-card-text>
            </v-card>
            </v-col>

                <template v-if="modelLoading">
                    <v-col cols="12" class="text-center" mt-4>
                        <v-progress-circular
                            :width="3"
                            color="blue"
                            indeterminate
                        ></v-progress-circular>
                    </v-col>
                </template>

                <template v-if="!modelLoading && model">

                    <MemberOrderList 
                        :items="availableOrders" 
                        :member="model"
                        title="Ready for Pickup"
                        item-key="id" 
                        @order-updated="orderUpdated"
                    />

                    <MemberOrderList 
                        :items="expiredOrders" 
                        :member="model"
                        title="Expired"
                        item-key="id" 
                        @order-updated="orderUpdated"
                    />

                    <MemberOrderList 
                        :items="completedOrders" 
                        :member="model"
                        title="Completed"
                        item-key="id" 
                        @order-updated="orderUpdated"
                    />

                    <MemberOrderList 
                        :items="refundedOrders" 
                        :member="model"
                        title="Refunded"
                        item-key="id" 
                        @order-updated="orderUpdated"
                    />

                </template>
            </template>
        </v-row>
    </v-container>
</template>

<script>
import moment from 'moment-timezone'
import MemberOrderList from './MemberOrderList.vue'
import AllMixins from '../../mixins/AllMixins.js'
export default {
    data () {
        return {
            search: null,
       //     members: [],
            model: null,
            modelLoading: false,
            loading: false,
            availableOrders: [],
            expiredOrders: [],
            completedOrders: [],
            refundedOrders: [],
            availableProducts: [],
        }
    },
    mounted () {
    //    this.loadMembers()
        this.getAllUsers()
        this.loadAvailableProducts()
    },
    mixins: [AllMixins],
    components: {
        MemberOrderList
    },
    computed: {
        searchMembers () {
            let members = this.members;
            members.sort((a, b) => (a.lastName > b.lastName) ? 1 : -1)
            members.forEach( (member) => {
                member.search = member.fullName + " (#" + member.memberId + ")";
            })
            return members
        }
    },
    watch: {
        model (val) {
            if(val != undefined){
                this.loadSelectedMemberOrders()
            }
        }
    },
    methods: {
        // loadMembers () {
        //     this.loading = true
        //     this.members = []
        //     //let self = this
        //     FIREBASE.db.collection("users").where('userStatus', '==', 'member')
        //     .get()
        //     .then( userQuery => {
        //         userQuery.forEach( userDoc => {
        //             this.members.push(userDoc.data())
        //         })
        //         this.loading = false
        //     })
        //     .catch(function(error) {
        //         console.log(error)
        //         this.loading = false
        //     })
        // },
        calendar (dateStr) {
            return moment(dateStr, "YYYY-MM-DD @HH:mm").calendar()
        },
        loadSelectedMemberOrders () {
            //let self = this
            this.modelLoading = true
            this.availableOrders = []
            this.completedOrders = []
            this.refundedOrders = []
            this.expiredOrders = []
            FIREBASE.db.collection("orders").where("uid","==",this.model.uid)
            .get()
            .then( querySnapshot => {
                var order
                querySnapshot.forEach( doc => {
                    order = doc.data()
                    if(order.status == "Refunded")
                        this.refundedOrders.push(order)
                    else{
                        if(order.status == "Completed")
                            this.completedOrders.push(order)
                        else{
                            if(this.productForOrderIsAvailable(order))
                                this.availableOrders.push(order)
                            else
                                this.expiredOrders.push(order)
                        }
                    }
                })
                this.availableOrders.sort((a, b) => (a.dateOrdered > b.dateOrdered) ? -1 : 1)
                this.expiredOrders.sort((a, b) => (a.dateOrdered > b.dateOrdered) ? -1 : 1)
                this.completedOrders.sort((a, b) => (a.dateOrdered > b.dateOrdered) ? -1 : 1)
                this.refundedOrders.sort((a, b) => (a.dateOrdered > b.dateOrdered) ? -1 : 1)
                this.modelLoading = false
            })
            .catch( error => {
                this.modelLoading = false
                console.log(error)
            })
        },
        loadAvailableProducts () {
            this.loading = true
            this.availableProducts = []
           // let self = this
            let now = moment().format("YYYY-MM-DD")
            FIREBASE.db.collection("products").where('pickupEnd', '>=', now)
            .get()
            .then( query => {
                query.forEach( doc => {
                    this.availableProducts.push(doc.data())
                })
                this.loading = false
            })
            .catch(function(error) {
                console.log(error)
                this.loading = false
            })
        },
        productForOrderIsAvailable (order){
            let match = false
            this.availableProducts.forEach( product => {
                if(order.productId == product.productId){
                    match = true
                }
            })
            return match
        },
        orderUpdated(){
            this.loadSelectedMemberOrders()
        }
    }
}
</script>