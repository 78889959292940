<template>
    <v-col cols="12">
        <v-card outlined>
            <v-row no-gutters>
                <v-col class="mt-n1 mb-n1">
                    <v-card-title class="primary--text font-weight-regular">
                        ({{items.length}}) {{title}}
                    </v-card-title>
                    </v-col>
                    <v-col v-if="items.length>0" cols="12" class="mt-n3">
                    <v-list rounded>
                        <v-list-group
                            v-for="item in items"
                            :key="item.code"
                            v-model="item.active"
                            no-action
                        >
                            <template v-slot:activator>
                                <v-list-item-content>
                                    <v-row no-gutters align="center">
                                        <v-col justify cols="7">
                                            <div class="subtitle-1 font-weight-medium">{{ item.name }}</div>
                                            <div class="body-2 font-weight-light">{{ "(" + item.line_items[0].quantity + ") " + item.line_items[0].variation_name}}</div>
                                        </v-col>
                                        <v-col align="right" cols="5">
                                            <div class="body-2 font-weight-light">{{calendar(item.dateOrdered)}}</div>
                                        </v-col>
                                    </v-row>
                                </v-list-item-content>
                            </template>
                            <v-list-item class="pl-2">
                                <v-row dense>
                                <v-col cols="12">
                                    <EditOrder 
                                    :order=item 
                                    :member="member"
                                    item-key="id"
                                    @order-updated="orderUpdated"
                                    />
                                </v-col>
                                </v-row>
                            </v-list-item>
                        </v-list-group>
                    </v-list>
                </v-col>
            </v-row>
        </v-card>
    </v-col>
</template>

<script>
import { mapMutations, mapGetters} from 'vuex'
import moment from 'moment-timezone'
import EditOrder from './EditOrder'
export default {
    data () {
        return {
            search: '',
            headers: [
                { text: 'Order', align: 'left', sortable: false, value: 'name' },
                { text: 'Status', align: 'right', sortable: false, value: 'status' },
                { text: '', value: 'data-table-expand' }
            ],
            selected: ''
        }
    },
    props: ['items', 'member', 'title'],
    components: {
        EditOrder
    },
    computed: {
        ...mapGetters ([
            'loading'
        ]),
    },
    methods: {
        calendar (dateStr) {
            return moment(dateStr, "YYYY-MM-DD HH:mm").calendar()
        },
        orderUpdated(){
            this.$emit('order-updated')
        }
    }
}
</script>